const education = [
  {
    title: "B.Sc in Computer Science",

    data: [],
  },
  {
    title: "Relevant Courses",

    data: [
      "Software Construction",
      "Algorithms and Data Structures",
      "Computation, Programs, and Programming",
      "Models of Computation",
    ],
  },
];

export default education;
